import { callbackToLabelMap, Ticket } from '@liftai/asset-management-types';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Autocomplete,
  Box,
  IconButton,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useState } from 'react';

import { LiftAITextLink } from '~/components/utils/helpers';
import { buildUrlForPropertyTickets, urlToHref } from '~/utils/urlBuilder';

type TicketOption = {
  ticket: Ticket;
  isLinked: boolean;
};

type ITicketLinkingProps = {
  tickets: Ticket[];
  ticketOptions: TicketOption[];
  invoiceId: string;
  isLoading: boolean;
  onAddTicket: (ticket: Ticket) => Promise<void>;
  onRemoveTicket: (ticket: Ticket) => Promise<void>;
};

const TicketListItem = ({
  ticket,
  startDate,
  endDate,
  invoiceId,
  onRemove,
}: {
  ticket: Ticket;
  startDate: string;
  endDate: string;
  invoiceId: string;
  onRemove: (ticket: Ticket) => void;
}) => {
  const url = buildUrlForPropertyTickets({
    startDate,
    endDate,
    number: ticket.number,
    propertyId: ticket.property.id,
    selectedInvoiceId: invoiceId,
    ticketId: ticket.id,
  });

  return (
    <ListItem key={ticket.id}>
      <Grid container flexDirection="row" alignItems="flex-start" justifyContent="space-between">
        <Grid size={{ xs: 10 }} container>
          <Grid size={{ xs: 12 }}>
            <Typography variant="body2" component="span">
              <LiftAITextLink label={ticket.number} href={urlToHref(url)} />
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Typography variant="body2" color="text.secondary">
              {callbackToLabelMap.get(ticket.entryType) ?? ticket.entryType}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Typography variant="body2" color="text.secondary">
              Time on site: {ticket.timeOnSiteRegularHours} | Travel Time:{' '}
              {ticket.travelTimeRegularHours}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Typography variant="body2" color="text.secondary">
              Time on site (OT): {ticket.timeOnSiteOvertime} | Travel Time (OT):{' '}
              {ticket.travelTimeOvertime}
            </Typography>
          </Grid>
        </Grid>
        <Grid size={{ xs: 1 }}>
          <IconButton onClick={() => onRemove(ticket)}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );
};

type TicketSelectorProps = {
  selectedTicket: TicketOption | null;
  setSelectedTicket: (ticket: TicketOption | null) => void;
  ticketOptions: TicketOption[];
  isLoading: boolean;
  onAddTicket: (ticket: Ticket) => Promise<void>;
};

const TicketSelector = ({
  selectedTicket,
  setSelectedTicket,
  ticketOptions,
  isLoading,
  onAddTicket,
}: TicketSelectorProps) => {
  const [isLinking, setIsLinking] = useState(false);

  const handleAddTicket = async () => {
    if (!selectedTicket) return;

    try {
      setIsLinking(true);
      await onAddTicket(selectedTicket.ticket);
    } finally {
      setSelectedTicket(null);
      setIsLinking(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#E7F2FF', padding: 2, borderRadius: 1, margin: 2, marginTop: 0 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid size={{ xs: 9 }}>
          <Autocomplete
            value={selectedTicket}
            onChange={(_, option) => setSelectedTicket(option)}
            getOptionDisabled={(option) => option.isLinked}
            noOptionsText="No tickets available"
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
            loading={isLoading}
            renderInput={(props) => <TextField {...props} label="Ticket Number" name="ticketId" />}
            options={ticketOptions}
            getOptionLabel={(option) =>
              `${option.ticket.number}${option.isLinked ? ' (Already linked)' : ''}`
            }
            size="small"
          />
        </Grid>
        <Grid size={{ xs: 3 }}>
          <LoadingButton
            onClick={handleAddTicket}
            loading={isLinking}
            color="primary"
            variant="outlined"
            size="medium"
            startIcon={<AddIcon />}
            disabled={!selectedTicket}
            fullWidth
          >
            Link
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const LinkedTickets = ({
  tickets,
  ticketOptions,
  invoiceId,
  isLoading,
  onAddTicket,
  onRemoveTicket,
}: ITicketLinkingProps) => {
  const [selectedTicket, setSelectedTicket] = useState<TicketOption | null>(null);

  let startDate = '';
  let endDate = '';
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(window.location.search);
    startDate = searchParams.get('start_date') ?? '';
    endDate = searchParams.get('end_date') ?? '';
  }

  return (
    <Box>
      <List>
        {tickets.map((ticket) => (
          <TicketListItem
            key={ticket.id}
            ticket={ticket}
            startDate={startDate}
            endDate={endDate}
            invoiceId={invoiceId}
            onRemove={onRemoveTicket}
          />
        ))}
        {tickets.length === 0 && (
          <ListItem>
            <Typography variant="body2">No linked tickets</Typography>
          </ListItem>
        )}
      </List>

      <TicketSelector
        selectedTicket={selectedTicket}
        setSelectedTicket={setSelectedTicket}
        ticketOptions={ticketOptions}
        isLoading={isLoading}
        onAddTicket={onAddTicket}
      />
    </Box>
  );
};

export default LinkedTickets;
