import { zodResolver } from '@hookform/resolvers/zod';
import type { PropertyForm } from '@liftai/asset-management-types';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useMemo } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import useSWR, { mutate } from 'swr';
import { z } from 'zod';

import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { usePropertyCars } from '~/hooks/usePropertyCars';
import { getApiClient } from '~/utils/api';

import type { FieldDef } from '../form/utils';
import { FieldAndCell } from '../form/utils';
import type { LAIUseFormReturn, LiftAIFormProps } from '../uploadDocument/SelectDocumentTypeDialog';

export const violationFormSchema = z.object({
  carId: z.string(),
  code: z.string().min(1, { message: 'Violation code is required' }),
  location: z.string().min(1, { message: 'Location is required' }),
  correctionDue: z.string(),
  dateOpen: z.string().refine((date) => new Date(date) <= new Date(), {
    message: 'Open date cannot be in the future',
  }),
  responsibility: z.enum(['owner', 'county', 'service_provider']),
  description: z.string().min(10, { message: 'Description must be at least 10 characters' }),
});

const violationFormSchemaWithPropertyId = violationFormSchema.extend({
  isCarChangeable: z.boolean(),
  propertyId: z.string().optional(),
});

export type ViolationFormData = z.infer<typeof violationFormSchemaWithPropertyId>;

const propertyOptionsFetcher = async (): Promise<{ properties: PropertyForm[] }> => {
  const apiClient = getApiClient();

  try {
    const properties = await apiClient.properties.getAll({ variant: 'form' });
    return { properties };
  } catch (error) {
    console.error('Failed to fetch properties:', error);
    return { properties: [] };
  }
};

export const ViolationFields = () => {
  const { control, watch } = useFormContext<ViolationFormData>();
  const propertyId = watch('propertyId');
  const isCarChangeable = watch('isCarChangeable');

  // Fetch properties
  const { data } = useSWR('ViolationForm.Options', propertyOptionsFetcher);

  // Use the usePropertyCars hook to get cars for selected property
  const { cars, loading: carsLoading } = usePropertyCars(propertyId ?? null);

  const formFields: FieldDef[] = useMemo(
    () => [
      ...(isCarChangeable
        ? [
            {
              name: 'propertyId',
              label: 'Property',
              type: 'autocomplete' as const,
              options:
                data?.properties?.map((property) => ({
                  id: property.id,
                  label: property.name,
                })) ?? [],
              loading: !data?.properties,
              required: true,
              gridProps: { xs: 12 },
            },
          ]
        : []),
      {
        name: 'carId',
        label: 'Car',
        type: 'autocomplete',
        options:
          cars?.map((car) => ({
            id: car.id,
            label: car.name + (car.ahjId ? ` - ${car.ahjId}` : ''),
          })) ?? [],
        loading: carsLoading,
        required: true,
        disabled: !propertyId || !isCarChangeable,
        gridProps: { xs: 12 },
      },
      {
        name: 'code',
        label: 'Violation Code',
        type: 'text',
        required: true,
        gridProps: { xs: 12 },
      },
      {
        name: 'location',
        label: 'Location',
        type: 'text',
        required: true,
        gridProps: { xs: 12 },
      },
      {
        name: 'dateOpen',
        label: 'Date Open',
        type: 'date',
        required: true,
        gridProps: { xs: 6 },
      },
      {
        name: 'correctionDue',
        label: 'Correction Due',
        type: 'date',
        required: true,
        gridProps: { xs: 6 },
      },
      {
        name: 'responsibility',
        label: 'Responsibility',
        type: 'select',
        required: true,
        options: [
          { id: 'owner', label: 'Owner' },
          { id: 'service_provider', label: 'Service Provider' },
          { id: 'county', label: 'County' },
        ],
        gridProps: { xs: 12 },
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        required: true,
        gridProps: { xs: 12 },
      },
    ],
    [data?.properties, cars, carsLoading, isCarChangeable, propertyId],
  );

  return (
    <>
      <Typography variant="h6" my={2}>
        Violation Details
      </Typography>
      <Grid container spacing={2}>
        {formFields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>
    </>
  );
};

export const ViolationForm: React.FC<LiftAIFormProps<ViolationFormData>> = ({
  children,
  document,
  onSubmit,
  initialData,
}) => {
  const { showEntityActionSnackbar } = useLAISnackbar();
  const { handleSubmit, reset } = useFormContext<ViolationFormData>();

  useEffect(() => {
    if (!initialData) {
      return;
    }
    reset(initialData);
  }, [initialData, reset]);

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}
      onSubmit={handleSubmit(
        async (data) => {
          try {
            const apiClient = getApiClient();
            const violationData = violationFormSchema.parse(data);
            const violation = await apiClient.violations.create(violationData);

            onSubmit(violation);
            // Mutate both certificate and violation data for this car
            void mutate(['violation', violationData.carId]);

            showEntityActionSnackbar(
              {
                name: 'Violation',
                action: ActionEnum.Create,
                id: violationData.carId,
              },
              {
                variant: 'success',
              },
            );
          } catch (err) {
            console.error('Violation form error:', err);
            showEntityActionSnackbar(
              {
                name: 'Violation',
                action: ActionEnum.Fail,
                id: data.carId,
              },
              {
                variant: 'error',
              },
            );
          }
        },
        (err) => {
          console.log('Violation validation error:', err);
        },
      )}
    >
      {children}
    </form>
  );
};

function useViolationForm(): LAIUseFormReturn<ViolationFormData> {
  const hookForm = useForm<ViolationFormData>({
    mode: 'all',
    resolver: zodResolver(violationFormSchema),
    defaultValues: {
      isCarChangeable: true,
      propertyId: '',
      carId: '',
      code: '',
      correctionDue: '',
      location: '',
      description: '',
      responsibility: 'owner',
    },
  });

  return {
    hookForm,
    Form: ViolationForm,
    Fields: ViolationFields,
  };
}

export { useViolationForm };
