import { zodResolver } from '@hookform/resolvers/zod';
import type {
  API_TicketCreateRequest,
  EntryTypeKey,
  InvoiceDisplay,
  PropertyForm,
  Ticket,
  TicketDto,
} from '@liftai/asset-management-types';
import {
  isOfCallBackType,
  MaintenanceType,
  ticketDtoSchema,
  ticketIsCallback,
} from '@liftai/asset-management-types';
import { Box, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid2';
import { useEffect, useMemo } from 'react';
import type { DefaultValues } from 'react-hook-form';
import { useForm, useFormContext } from 'react-hook-form';
import useSWR, { type Fetcher, useSWRConfig } from 'swr';

import type {
  LAIUseFormReturn,
  LiftAIFormProps,
} from '~/components/uploadDocument/SelectDocumentTypeDialog';
import { associateFileToEntity } from '~/components/utils/upload';
import { getTicketMutator } from '~/data/hooks/utils';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';
import { usePropertyCars } from '~/hooks/usePropertyCars';
import { useProviders } from '~/hooks/useProviders';
import hasExternalSourceMetadata from '~/lib/metadata/hasExternalSourceMetadata';
import { getApiClient } from '~/utils/api';
import { toDateTimeLocal } from '~/utils/format';

import { FieldAndCell } from '../form/utils';
import { buildFieldDef } from '../tickets/addTicketFields';

const UNIQUE_TICKET_NUMBER_ERROR = 'Ticket number already exists for the same Service Provider.';

const ticketOptionsFetcher: Fetcher<
  {
    properties: PropertyForm[];
    invoices: InvoiceDisplay[];
  },
  readonly [key: string]
> = async ([_]) => {
  const apiClient = getApiClient();
  const [properties, invoices] = await Promise.all([
    apiClient.properties.getAll({ variant: 'form' }),
    apiClient.invoices.getAll({ variant: 'form' }),
  ]);

  return { properties, invoices };
};

const getDefaultValues = (): DefaultValues<TicketDto> => {
  return {
    number: '',
    entryType: MaintenanceType.Visit as EntryTypeKey,
    propertyId: undefined,
    carIds: [],
    invoiceId: null,
    workPerformed: '',
    callTime: '',
    startedTime: '',
    finishedTime: '',
    problemDescription: '',
    countTowardsKpis: false,
    notes: '',
    timeOnSiteRegularHours: '',
    travelTimeRegularHours: '',
    timeOnSiteOvertime: '',
    travelTimeOvertime: '',
    problemType: undefined,
    serviceProviderGroup: undefined,
  };
};

// Default edit status
const defaultDisabledFields = {
  number: false,
  entryType: false,
  propertyId: false,
  carIds: false,
  invoiceId: false,
  workPerformed: false,
  callTime: false,
  startedTime: false,
  finishedTime: false,
  problemDescription: false,
  countTowardsKpis: false,
  notes: false,
  timeOnSiteRegularHours: false,
  travelTimeRegularHours: false,
  timeOnSiteOvertime: false,
  travelTimeOvertime: false,
  equipmentFailure: null,
  problemType: false,
} as const;

const getDisabledFields = (ticket: Pick<Ticket, 'metadata'>) => {
  // if the ticket came from E2M or other external source, it is not allowed to edit some fields.
  if (ticket.metadata && hasExternalSourceMetadata(ticket)) {
    return {
      ...defaultDisabledFields,
      entryType: true,
      propertyId: true,
      carIds: true,
      invoiceId: true,
      workPerformed: true,
      callTime: true,
      startedTime: true,
      finishedTime: true,
      problemDescription: true,
      countTowardsKpis: true,
      notes: true,
      timeOnSiteRegularHours: true,
      travelTimeRegularHours: true,
      timeOnSiteOvertime: true,
      travelTimeOvertime: true,
      problemType: true,
    };
  }

  return defaultDisabledFields;
};

export const mapTicketToFormValues = (ticket: Ticket): TicketDto => {
  const base = {
    ...getDefaultValues(),
    id: ticket.id,
    propertyId: ticket.property?.id ?? undefined,
    invoiceId: ticket.invoice ? ticket.invoice.id : null,
    serviceProviderGroup: ticket.serviceProviderGroup?.id ?? undefined,
    carIds: ticket.carsServiced?.map((car) => car.id) ?? [],
    entryType: ticket.entryType,
    number: ticket.number,
    startedTime: toDateTimeLocal(ticket.startedTime),
    finishedTime: toDateTimeLocal(ticket.finishedTime),
    workPerformed: ticket.workPerformed,
    timeOnSiteRegularHours: ticket.timeOnSiteRegularHours,
    travelTimeRegularHours: ticket.travelTimeRegularHours,
    timeOnSiteOvertime: ticket.timeOnSiteOvertime,
    travelTimeOvertime: ticket.travelTimeOvertime,
    countTowardsKpis: ticket.countTowardsKpis,
    notes: ticket.notes,
  } as const;

  if (ticketIsCallback(ticket)) {
    return {
      ...base,
      callTime: ticket.callTime ? toDateTimeLocal(ticket.callTime) : null,
      problemDescription: ticket.problemDescription,
      problemType: ticket.problemType,
    } as TicketDto;
  }

  return {
    ...base,
  } as TicketDto;
};

const createTicket = async (dto: TicketDto): Promise<Ticket> => {
  const { propertyId, invoiceId, carIds, serviceProviderGroup, ...payload } = dto;
  const apiClient = getApiClient();

  const ticketPayload: API_TicketCreateRequest = {
    ...payload,
    property: propertyId,
    invoice: invoiceId,
    carsServiced: carIds,
    serviceProviderGroup,
  };

  const ticket = await (ticketPayload.id
    ? apiClient.tickets.update(ticketPayload)
    : apiClient.tickets.create(ticketPayload));

  return ticket;
};

export const useTicketForm = (): LAIUseFormReturn<TicketDto, Ticket> => {
  const hookForm = useForm<TicketDto>({
    // TODO: Change this to onSubmit, used for quick debugging
    mode: 'all',
    defaultValues: getDefaultValues(),
    resolver: zodResolver(ticketDtoSchema),
  });

  return { hookForm, Form: TicketForm, Fields: TicketFields };
};

export const TicketForm = ({
  children,
  document,
  onSubmit,
  initialData,
}: LiftAIFormProps<TicketDto, Ticket>) => {
  const { mutate } = useSWRConfig();
  const { showEntityActionSnackbar, showSnackbar } = useLAISnackbar();
  const { handleSubmit, watch, clearErrors, reset, resetField, setError } =
    useFormContext<TicketDto>();

  const ticketType = watch('entryType');
  const propertyId = watch('propertyId');

  // Clear errors when ticket type changes, otherwise we get stuck with old errors
  useEffect(() => {
    clearErrors();
  }, [ticketType, clearErrors]);

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const formValues = {
      ...getDefaultValues(),
      ...initialData,
    };

    reset(formValues);
  }, [initialData, reset]);

  useEffect(() => {
    if (propertyId) {
      resetField('carIds');
    }
  }, [propertyId, resetField]);

  return (
    <form
      noValidate
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      onSubmit={handleSubmit(
        async (data, e) => {
          try {
            const ticketDto = ticketDtoSchema.parse(data);
            const ticket = await createTicket(ticketDto);

            if (document) {
              await associateFileToEntity(
                document.attachment.id,
                ticket.id,
                'ticket',
                document.fileType === 'pdf'
                  ? {
                      pageNumber: document.pageNum,
                      isPrimary: true,
                    }
                  : {},
              );
            }

            if (!data.serviceProviderGroup) {
              throw new Error('Service Provider Group is required');
            }
            onSubmit(ticket);

            const action = data.id ? ActionEnum.Update : ActionEnum.Create;
            showEntityActionSnackbar(
              {
                name: 'Ticket',
                action,
                id: data.number,
              },
              {
                variant: 'info',
              },
            );

            void mutate(getTicketMutator(data.id));
          } catch (err) {
            console.error('TicketForm save error: ', err);

            if (err instanceof Error && err.message === UNIQUE_TICKET_NUMBER_ERROR) {
              setError('number', {
                message: UNIQUE_TICKET_NUMBER_ERROR,
              });
            }

            showSnackbar('Ticket failed to save', {
              variant: 'error',
            });
          }
        },
        (err, e) => {
          console.error('TicketForm handleSubmit error: ', err);
        },
      )}
    >
      {children}
    </form>
  );
};

export const TicketFields: React.FC = () => {
  const { control, watch, setValue } = useFormContext<TicketDto>();
  const propertyId = watch('propertyId');
  const ticketType = watch('entryType');
  const metadata = watch('metadata');
  const selectedInvoiceId = watch('invoiceId');
  const { data } = useSWR(['TicketForm.Options'], ticketOptionsFetcher);

  const disabledFields = getDisabledFields({ metadata });
  const { cars } = usePropertyCars(propertyId);

  // Update disabledFields to disable invoiceId field when no property is selected
  const disabledFieldsWithInvoice = useMemo(() => {
    return {
      ...disabledFields,
      invoiceId: !propertyId || disabledFields.invoiceId,
    };
  }, [disabledFields, propertyId]);

  const invoices = useMemo(
    () => data?.invoices.filter((i) => i.property.id === propertyId) ?? [],
    [data, propertyId],
  );

  const serviceProviders = useProviders({ propertyId });
  // Find the selectedInvoiceId in the invoices array
  const selectedInvoice = invoices.find((invoice) => invoice.id === selectedInvoiceId);

  // If the invoiceId is set, filter the serviceProviders to only include the ones that are associated with the invoice else return all serviceProviders
  const filteredServiceProviders = useMemo(() => {
    return selectedInvoice?.serviceProvider?.id
      ? serviceProviders.providers?.filter(
          (provider) => selectedInvoice?.serviceProvider?.id === provider.id,
        )
      : serviceProviders.providers;
  }, [selectedInvoice, serviceProviders]);

  const fields = useMemo(
    () =>
      buildFieldDef({
        ticketType,
        properties: data?.properties,
        cars,
        invoices,
        serviceProviderGroup: filteredServiceProviders ?? [],
        disabledFields: disabledFieldsWithInvoice,
      }),
    [
      data?.properties,
      ticketType,
      cars,
      invoices,
      disabledFieldsWithInvoice,
      filteredServiceProviders,
    ],
  );

  const showNotice: boolean = useMemo(() => {
    return Object.values(disabledFields).some((field) => field === true);
  }, [disabledFields]);

  const { generalFields, ticketFields, checkboxField, notesField } = fields;

  useEffect(() => {
    if (!isOfCallBackType(ticketType)) {
      setValue('countTowardsKpis', true);
    } else {
      setValue('countTowardsKpis', false);
    }
  }, [ticketType, setValue]);

  return (
    <>
      {showNotice ? (
        <Alert severity="info">
          This ticket was automatically transferred from the service provider{"'"}s system and
          cannot be modified.
        </Alert>
      ) : null}
      <Typography variant="h6" my={2}>
        General
      </Typography>
      <Grid container spacing={2} my={2}>
        {generalFields.map((field) => (
          <FieldAndCell key={field.name} field={field} control={control} />
        ))}
      </Grid>
      <Box my={2}>
        <Typography variant="h6" py={2}>
          Ticket
        </Typography>
        <Grid container spacing={2}>
          {ticketFields.map((field) => (
            <FieldAndCell key={field.name} field={field} control={control} />
          ))}
        </Grid>
      </Box>
      {checkboxField ? (
        <Grid container>
          <FieldAndCell field={checkboxField} control={control} />
        </Grid>
      ) : null}
      <Grid container spacing={1} mt={2}>
        <FieldAndCell field={notesField} control={control} />
      </Grid>
    </>
  );
};

export default TicketFields;
