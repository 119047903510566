import { Dialog, DialogContent, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { useViolationForm, ViolationFormData } from '~/components/uploadDocument/violationForm';

import DialogBackNextActions from '../DialogBackNextActions';
import DialogTitleWithCloseButton from '../DialogTitleWithCloseButton';

interface IAddViolationDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data?: ViolationFormData) => void;
  isEdit?: boolean;
  defaultValues?: ViolationFormData;
}

const AddViolationDialog = ({
  open,
  onClose,
  onSubmit,
  isEdit,
  defaultValues,
}: IAddViolationDialogProps) => {
  const theme = useTheme();
  const { hookForm, Form: ViolationForm, Fields: ViolationFields } = useViolationForm();
  const {
    formState: { isSubmitting },
  } = hookForm;

  const actionBtns = {
    next: { label: isSubmitting ? 'Saving' : 'Save', enabled: !isSubmitting },
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <FormProvider {...hookForm}>
        <ViolationForm onSubmit={onSubmit} initialData={defaultValues}>
          <DialogTitleWithCloseButton
            onClose={onClose}
            title={`${isEdit ? 'Edit' : 'Add'} Violation`}
          />
          <DialogContent
            sx={{ padding: theme.spacing(2), paddingTop: 0, overflow: 'auto', height: '100%' }}
          >
            <ViolationFields />
          </DialogContent>
          <DialogBackNextActions onBack={onClose} actionBtns={actionBtns} />
        </ViolationForm>
      </FormProvider>
    </Dialog>
  );
};

export default AddViolationDialog;
