import {
  ContractCustomDetails,
  ContractRateSet,
  ContractReviewRule,
  PropertyContract,
} from '@liftai/asset-management-types';
import {
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Skeleton,
  Tab,
  Tabs,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { lazy, Suspense, useCallback, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { mutate } from 'swr';

import { useContractReviewRulesForm } from '~/components/contracts/ContractReviewRulesForm';
import DialogTitleWithCloseButton from '~/components/DialogTitleWithCloseButton';
import usePropertyContracts from '~/data/hooks/usePropertyContracts';
import useLAISnackbar, { ActionEnum } from '~/hooks/useLAISnackbar';

// Lazy-loaded components to improve initial load time
const LazyContractGeneralSectionForm = lazy(() =>
  import('~/components/contracts/ContractGeneralSectionForm').then((module) => ({
    default: module.ContractGeneralSectionForm,
  })),
);

const LazyContractRateSetsSectionForm = lazy(() =>
  import('~/components/contracts/ContractRateSetsSectionForm').then((module) => ({
    default: module.ContractRateSetsSectionForm,
  })),
);

interface SkeletonProps {
  count: number;
  width: string;
  height?: number;
}

const TabLoading: React.FC = () => {
  const renderTextSkeletons = ({ count, width }: SkeletonProps) => {
    return Array.from({ length: count }).map((_, index) => (
      <Skeleton key={index} variant="text" sx={{ marginRight: 2, minWidth: width }} />
    ));
  };

  const renderRectangularSkeletons = ({ count, width, height = 40 }: SkeletonProps) => {
    return Array.from({ length: count }).map((_, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        height={height}
        sx={{ marginRight: 2, minWidth: width }}
      />
    ));
  };

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid size={{ xs: 12 }} sx={{ minWidth: '200px' }}>
        <Grid container spacing={2} sx={{ padding: 1 }}>
          {renderTextSkeletons({ count: 3, width: '240px' })}
        </Grid>
        <Grid container spacing={2} sx={{ padding: 1 }}>
          {renderRectangularSkeletons({ count: 3, width: '246px' })}
        </Grid>
      </Grid>

      <Grid size={{ xs: 12 }} sx={{ minWidth: '200px' }}>
        <Grid container spacing={2} sx={{ padding: 1 }}>
          {renderTextSkeletons({ count: 4, width: '177px' })}
        </Grid>
        <Grid container spacing={2} sx={{ padding: 1 }}>
          {renderRectangularSkeletons({ count: 4, width: '177px' })}
        </Grid>
      </Grid>

      {[1, 2].map((_, index) => (
        <Grid key={index} size={{ xs: 12 }} sx={{ maxWidth: '815px' }}>
          <Skeleton variant="rectangular" height={40} width="100%" />
        </Grid>
      ))}
    </Grid>
  );
};

enum TabType {
  General = 'general',
  RateSets = 'rate-sets',
  ReviewRules = 'review-rules',
}

interface EditContractDetailsDialogProps {
  open: boolean;
  defaultValues: PropertyContract['contract'] & {
    customDetails?: ContractCustomDetails;
    monthlyPrice: number;
  };
  propertyId: string;
  monthlyPrice: number;
  startDate: string;
  expirationDate: string;
  propertyContractId: string;
  rateSets: ContractRateSet[];
  rateSetId: string;
  onClose: () => void;
}

const ContractReviewRulesSectionForm = ({
  onClose,
  defaultValues,
  propertyId,
}: {
  onClose: () => void;
  defaultValues: PropertyContract['contract'] & { reviewRules: ContractReviewRule[] };
  propertyId: string;
}) => {
  const { Fields, Form, hookForm } = useContractReviewRulesForm();

  const initialData = useMemo(
    () => ({ reviewRules: defaultValues.reviewRules }),
    [defaultValues.reviewRules],
  );

  const { saveContractReviewRules, deleteContractReviewRule } = usePropertyContracts(propertyId);
  const { showEntityActionSnackbar } = useLAISnackbar();

  const onSubmitContractReviewRules = useCallback(
    async ({ reviewRules }: { reviewRules: ContractReviewRule[] }) => {
      try {
        await saveContractReviewRules(defaultValues.id, reviewRules);

        await Promise.all([
          mutate(['contracts', propertyId]),
          mutate(['contract', propertyId, defaultValues.id]),
        ]);

        showEntityActionSnackbar(
          {
            name: 'Contract information',
            action: ActionEnum.Update,
          },
          {
            variant: 'info',
            autoHideDuration: 7000,
          },
        );
      } catch (error) {
        console.error(error);
        hookForm.setError('root.serverError', {
          type: '400',
          message: 'Failed to save contract details',
        });
      }
    },
    [defaultValues.id, propertyId, saveContractReviewRules, showEntityActionSnackbar, hookForm],
  );

  const onDeleteReviewRule = useCallback(
    async (id: string) => {
      try {
        await deleteContractReviewRule(defaultValues.id, id);

        await Promise.all([
          mutate(['contracts', propertyId]),
          mutate(['contract', propertyId, defaultValues.id]),
        ]);

        showEntityActionSnackbar(
          {
            name: 'Contract information',
            action: ActionEnum.Update,
          },
          {
            variant: 'info',
            autoHideDuration: 7000,
          },
        );
      } catch (error) {
        console.error(error);
        hookForm.setError('root.serverError', {
          type: '400',
          message: 'Failed to save contract details',
        });
      }
    },
    [defaultValues.id, propertyId, deleteContractReviewRule, showEntityActionSnackbar, hookForm],
  );

  return (
    <FormProvider {...hookForm}>
      <Grid maxWidth="md" container sx={{ flexGrow: 1 }}>
        <Form onSubmit={onSubmitContractReviewRules} initialData={initialData}>
          <Fields onDelete={onDeleteReviewRule} />
          {hookForm.formState.errors.root?.serverError ? (
            <FormHelperText sx={{ color: 'red', display: 'flex', justifyContent: 'flex-end' }}>
              {hookForm.formState.errors.root?.serverError.message}
            </FormHelperText>
          ) : null}
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, marginTop: 1 }}
          >
            <Button type="button" variant="outlined" onClick={onClose}>
              close
            </Button>
            <Button type="submit" variant="contained">
              save
            </Button>
          </Grid>
        </Form>
      </Grid>
    </FormProvider>
  );
};

export const EditContractDetailsDialog = ({
  open,
  onClose,
  defaultValues,
  propertyId,
  monthlyPrice,
  startDate,
  expirationDate,
  propertyContractId,
  rateSets,
  rateSetId,
}: EditContractDetailsDialogProps) => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState(TabType.General);

  const handleTabChange = useCallback((_event: React.SyntheticEvent, newValue: TabType) => {
    setActiveTab(newValue);
  }, []);

  const sharedFormProps = useMemo(
    () => ({
      onClose,
      propertyId,
    }),
    [onClose, propertyId],
  );

  const generalFormProps = useMemo(
    () => ({
      ...sharedFormProps,
      defaultValues,
      monthlyPrice,
      startDate,
      expirationDate,
      propertyContractId,
      rateSetId,
      rateSets,
    }),
    [
      sharedFormProps,
      defaultValues,
      monthlyPrice,
      startDate,
      expirationDate,
      propertyContractId,
      rateSetId,
      rateSets,
    ],
  );

  const rateSetsFormProps = useMemo(
    () => ({
      ...sharedFormProps,
      contractId: defaultValues.id,
      rateSets,
    }),
    [sharedFormProps, defaultValues.id, rateSets],
  );

  const hourlyRatesFormProps = useMemo(
    () => ({
      ...sharedFormProps,
      defaultValues,
    }),
    [sharedFormProps, defaultValues],
  );

  const tabContent = useMemo(() => {
    switch (activeTab) {
      case TabType.General:
        return (
          <Suspense fallback={<TabLoading />}>
            <LazyContractGeneralSectionForm {...generalFormProps} />
          </Suspense>
        );
      case TabType.RateSets:
        return (
          <Suspense fallback={<TabLoading />}>
            <LazyContractRateSetsSectionForm {...rateSetsFormProps} />
          </Suspense>
        );
      case TabType.ReviewRules:
        return <ContractReviewRulesSectionForm {...hourlyRatesFormProps} />;
      default:
        return null;
    }
  }, [activeTab, generalFormProps, rateSetsFormProps, hourlyRatesFormProps]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" aria-modal>
      <DialogTitleWithCloseButton onClose={onClose} title="Edit Contract Summary" />
      <DialogContent
        sx={{
          padding: theme.spacing(2),
          paddingTop: 0,
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{ marginBottom: 3.5, borderColor: 'divider' }}
        >
          <Tab value={TabType.General} label="General" />
          <Tab value={TabType.RateSets} label="Rate Sets" />
          <Tab value={TabType.ReviewRules} label="Invoice Review Rules" />
        </Tabs>

        {tabContent}
      </DialogContent>
    </Dialog>
  );
};
