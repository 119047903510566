import './instrument';

import * as amplitude from '@amplitude/analytics-browser';
import { plugin as engagementPlugin } from '@amplitude/engagement-browser';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY);
amplitude.add(engagementPlugin());

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
