import type { Path } from 'react-router';
import { useLocation, useNavigate } from 'react-router';

import { selectedInvoiceIdKey } from '~/components/global/constants';

type UseLAINavigateOptions =
  | {
      paramsToKeep?: never;
      keepSearch: boolean;
    }
  | {
      paramsToKeep: string[];
      keepSearch?: never;
    };

export const defaultParamsToKeep = [selectedInvoiceIdKey] as const;

export const useLAINavigate = () => {
  const remixNavigate = useNavigate();
  const { search } = useLocation();

  const navigate = (
    path: string | Partial<Path>,
    { paramsToKeep, keepSearch }: UseLAINavigateOptions = {
      paramsToKeep: [...defaultParamsToKeep],
    },
  ) => {
    const currentParams = new URLSearchParams(search);
    const nextParams = new URLSearchParams();

    if (keepSearch) {
      // keep the current search params
      for (const [key, value] of currentParams.entries()) {
        nextParams.append(key, value);
      }
    } else if (paramsToKeep) {
      for (const param of paramsToKeep) {
        const values = currentParams.getAll(param);

        if (values.length > 0) {
          for (const value of values) {
            nextParams.append(param, value);
          }
        }
      }
    }

    if (typeof path !== 'string') {
      if (path.search) {
        const searchParams = new URLSearchParams(path.search);
        for (const [key, value] of searchParams.entries()) {
          nextParams.append(key, value);
        }
      }
    }

    void remixNavigate({
      pathname: typeof path === 'string' ? path : path.pathname,
      search: '?' + nextParams.toString(),
    });
  };

  return {
    navigate,
  };
};
