import { Box } from '@mui/material';
import { useContext } from 'react';
import { useLocation } from 'react-router';

import { TabContext } from '~/components/common/tabs/useTabs';

interface TabPanelProps {
  tabId: string;
  children?: React.ReactNode;
}

export default function TabPanel({ tabId, children }: TabPanelProps) {
  const { selectedTab } = useContext(TabContext);
  const location = useLocation();
  const isVisible = selectedTab === tabId;

  // Check if current path is Property
  const isPropertyPage = location.pathname.match(/^\/portfolio\/[0-9a-f-]+$/i) !== null;
  return (
    <Box
      role="tabpanel"
      id={tabId}
      display={isVisible ? 'flex' : 'none'}
      width="100%"
      aria-labelledby={tabId}
      sx={{ flex: '1 0 auto', ...(isPropertyPage && { padding: '0px 8px 0px 8px' }) }}
    >
      {children}
    </Box>
  );
}
