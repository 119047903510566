import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LicenseInfo } from '@mui/x-license';
import { captureException } from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { RouterProvider } from 'react-router';
import { SWRConfig } from 'swr';

import useLAISnackbar, { iconVariants } from '~/hooks/useLAISnackbar';
import { router } from '~/routes/router';
import { theme } from '~/theme';

if (import.meta.env.VITE_MUI_X_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_X_LICENSE_KEY);
}

const LAISWRConfig = ({ children }: { children: React.ReactNode }) => {
  const { showSnackbar } = useLAISnackbar();
  return (
    <SWRConfig
      value={{
        onError: (error) => {
          captureException(error);
          const message = error instanceof Error ? error.message : 'An unknown error occurred';
          showSnackbar(message, {
            variant: 'error',
          });
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

function App() {
  return (
    <>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            iconVariant={iconVariants}
            maxSnack={3}
            classes={{
              containerRoot: 'snackbar-container-root',
            }}
          >
            <LAISWRConfig>
              <RouterProvider router={router} />
            </LAISWRConfig>
          </SnackbarProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
