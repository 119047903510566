import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router';

import { InvoiceDetailDrawerFlowContext } from '~/components/invoices/InvoiceDetailDrawerContext';
import Main from '~/components/layout/Main';

function App() {
  const { isOpen } = useContext(InvoiceDetailDrawerFlowContext);
  const location = useLocation();

  // Check if current path is Property
  const isPropertyPage = location.pathname.match(/^\/portfolio\/[0-9a-f-]+$/i) !== null;

  return (
    <Main sidebarOpen={isOpen} noPadding={isPropertyPage}>
      <Outlet />
    </Main>
  );
}

export default App;
