import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router';

import { TabPanel, useTabs } from '~/components/common/tabs';
import { TabContext, TabStrip, type UseTabsOpts } from '~/components/common/tabs/useTabs';
import { selectedTabSearchParam } from '~/components/global/constants';
import {
  getPortfolioOverviewSearchParams,
  PortfolioOverview,
} from '~/components/portfolio/PortfolioOverview';
import { PropertyCars } from '~/components/portfolio/PropertyCars';
import { PropertyContracts } from '~/components/portfolio/PropertyContracts';
import { PropertySpending } from '~/components/portfolio/PropertySpending';
import { PropertyTickets } from '~/components/portfolio/PropertyTickets';
import type { PropertyDetails } from '~/components/portfolio/types';
import usePropertyContracts from '~/data/hooks/usePropertyContracts';

enum Tab {
  Overview = 'overview',
  Contracts = 'contracts',
  Cars = 'elevators',
  Tickets = 'tickets',
  Spending = 'spending',
}

export { Tab as PortfolioTab };

export const carSearchParam = 'carId';

const tabLabels = [Tab.Overview, Tab.Contracts, Tab.Cars, Tab.Tickets, Tab.Spending];

type PortfolioDetailTabsProps = {
  defaultTab: Tab;
};

const usePortfolioDetailTabs = ({ defaultTab = Tab.Overview }: PortfolioDetailTabsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabFromSearchParams = searchParams.get(selectedTabSearchParam);

  const onTabChange = useCallback(
    (tabName: string) => {
      setSearchParams((searchParams) => {
        if (tabName !== searchParams.get(selectedTabSearchParam)) {
          searchParams.set(selectedTabSearchParam, tabName);
        }
        return searchParams;
      });
    },
    [setSearchParams],
  );

  const tabsOpts: UseTabsOpts = {
    onTabChange: onTabChange,
  };

  const { selectTab, context, labelToTabId } = useTabs(
    tabLabels,
    tabFromSearchParams ?? defaultTab,
    tabsOpts,
  );

  useEffect(() => {
    if (tabFromSearchParams) {
      selectTab(tabFromSearchParams);
    }
  }, [tabFromSearchParams, selectTab]);

  return {
    context,
    labelToTabId,
  };
};

export type PortfolioTabContainerProps = { property: PropertyDetails };
export const PortfolioTabContainer: React.FC<PortfolioTabContainerProps> = ({ property }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const hasCarId = searchParams.has(carSearchParam);
  const selectedTab = searchParams.get(selectedTabSearchParam);
  const defaultSelectedTab = hasCarId ? Tab.Cars : Tab.Overview;

  const { context, labelToTabId } = usePortfolioDetailTabs({
    defaultTab: (selectedTab as Tab) ?? defaultSelectedTab,
  });

  const portfolioOverviewSearchParams = useMemo(() => {
    const next = new URLSearchParams(searchParams);
    if (property.id) {
      next.set('property', property.id);
    }
    return getPortfolioOverviewSearchParams(next);
  }, [property.id, searchParams]);

  const { contracts, isLoading } = usePropertyContracts(property.id);

  const isPropertyPage = location.pathname.match(/^\/portfolio\/[0-9a-f-]+$/i) !== null;
  const styles = {
    box: { borderColor: 'divider' },
    tabs: { backgroundColor: 'none', marginBottom: isPropertyPage ? '8px' : 3.5 },
  };

  return (
    <Stack flex={1} sx={{ '.MuiTabs-fixed': { bgcolor: '#fff' } }}>
      <TabContext.Provider value={context}>
        <TabStrip styles={styles} />
        <TabPanel tabId={labelToTabId[Tab.Overview]}>
          <PortfolioOverview
            propertyId={property.id}
            searchParams={portfolioOverviewSearchParams}
            summary={property.summary}
            trends={property.trends}
          />
        </TabPanel>
        <TabPanel tabId={labelToTabId[Tab.Contracts]}>
          {!isLoading ? (
            <PropertyContracts
              propertyContracts={contracts}
              propertyName={property.name}
              propertyId={property.id}
            />
          ) : (
            <div>Loading contracts...</div>
          )}
        </TabPanel>
        <TabPanel tabId={labelToTabId[Tab.Cars]}>
          <PropertyCars propertyId={property.id} />
        </TabPanel>
        <TabPanel tabId={labelToTabId[Tab.Tickets]}>
          <PropertyTickets propertyId={property.id} />
        </TabPanel>
        <TabPanel tabId={labelToTabId[Tab.Spending]}>
          <PropertySpending propertyId={property.id} />
        </TabPanel>
      </TabContext.Provider>
    </Stack>
  );
};
