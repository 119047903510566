import type { Invoice, Ticket } from '@liftai/asset-management-types';
import { Box, Stack } from '@mui/material';
import { useContext } from 'react';

import { UserContext } from '~/auth/userContext';
import { Notes } from '~/components/common/Notes';
import { TabPanel, useTabs } from '~/components/common/tabs';
import { TabContext, TabStrip } from '~/components/common/tabs/useTabs';
import Attachments from '~/components/invoices/Attachments';
import InvoiceOverview from '~/components/invoices/InvoiceOverview';
import useInvoice from '~/data/hooks/useInvoice';

enum Tab {
  Overview = 'overview',
  Notes = 'notes',
  Attachments = 'attachments',
}

const tabLabels = [Tab.Overview, Tab.Notes, Tab.Attachments];
const defaultSelected = Tab.Overview;

interface InvoiceTabContainerProps {
  invoice?: Invoice;
  tickets: Ticket[] | undefined;
}

export default function InvoiceTabContainer({ invoice, tickets }: InvoiceTabContainerProps) {
  const { context, labelToTabId } = useTabs(tabLabels, defaultSelected);

  const {
    userDetails: { id: userId },
  } = useContext(UserContext);

  const {
    addNoteToInvoice,
    invoiceNotes: { notes },
  } = useInvoice(invoice?.id ?? null);

  return (
    <Stack flex={1}>
      <TabContext.Provider value={context}>
        <TabStrip />
        <Box flex={1} sx={{ display: 'flex' }}>
          <TabPanel tabId={labelToTabId[Tab.Overview]}>
            <InvoiceOverview invoice={invoice} tickets={tickets} />
          </TabPanel>
          <TabPanel tabId={labelToTabId[Tab.Notes]}>
            <Notes notes={notes} onAddNote={(note) => addNoteToInvoice(userId, note)} />
          </TabPanel>
          <TabPanel tabId={labelToTabId[Tab.Attachments]}>
            <Attachments invoice={invoice} tickets={tickets} />
          </TabPanel>
        </Box>
      </TabContext.Provider>
    </Stack>
  );
}
