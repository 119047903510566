import type { SxProps, Theme } from '@mui/material';
import { Box, Tab, Tabs } from '@mui/material';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { selectedColor } from '~/theme';

const TAB_PREFIX = `tab-`;
const TAB_PANEL_PREFIX = `tabpanel-`;

export interface TabDefinition {
  label: string;
  tabId: string;
  panelId: string;
}

export const tabDefFromLabel = (label: string): TabDefinition => {
  const id = label.toLowerCase().replace(/ /g, '');
  return { label, tabId: `${TAB_PREFIX}${id}`, panelId: `${TAB_PANEL_PREFIX}${id}` };
};

type TabContextType = {
  selectedTab: string;
  setSelectedTab: (tabName: string) => void;
  onTabChange: (tabId: string) => void;
  tabDefs: TabDefinition[];
};

export const TabContext = createContext<TabContextType>({
  selectedTab: '',
  setSelectedTab: () => {},
  onTabChange: () => {},
  tabDefs: [],
});

type TabStyles = {
  /**
   * styles that applies to the surrounded `<Box>` as follows
   * @example <Box sx={opts.styles?.box}><Tabs>...</Tabs></Box>
   */
  box?: SxProps<Theme>;
  /**
   * styles that applies to the `<Tabs>` components
   * @example <Tabs sx={opts.styles?.tabs ?? { backgroundColor: selectedColor }}>...</Tabs>
   * @default { backgroundColor: selectedColor }
   */
  tabs?: SxProps<Theme>;
};

type TabStripProps = {
  /** styles that can be applied */
  styles?: TabStyles;

  /** list of tab labels to disable */
  disable?: string[];

  /** list of tab labels to override */
  overrideLabels?: {
    [key: string]: string;
  };
};

export const TabStrip = ({ styles, disable, overrideLabels }: TabStripProps) => {
  const { selectedTab, onTabChange, tabDefs } = useContext(TabContext);

  return (
    <Box sx={styles?.box}>
      <Tabs
        sx={{ backgroundColor: selectedColor, ...styles?.tabs }}
        value={selectedTab}
        onChange={(_event, newTab) => onTabChange(newTab)}
      >
        {tabDefs.map(({ tabId: key, tabId: id, tabId: value, label, panelId }) => (
          <Tab
            key={key}
            {...{ id, value, label: overrideLabels?.[label] ?? label }}
            aria-controls={panelId}
            disabled={disable?.includes(label)}
          />
        ))}
      </Tabs>
    </Box>
  );
};

type UseTabsReturn = {
  selectedTab: string;
  labelToTabId: Record<string, string>;
  selectTab: (tabName: string) => void;
  context: TabContextType;
};

/** tabsOptions customization options for the `Tabs` component*/
export type UseTabsOpts = {
  /**
   * callback function to be called when a tab is clicked
   */
  onTabChange?: (tabId: string) => void;
};

/**
 * A hook which accepts a list of tab labels and returns a `<Tabs>` instance
 * pre-configured with them and a `selectedTab` state variable which reflects what
 * the currently selected tab is.
 *
 * The hook also returns a `tabs` object which is a map of the tab labels to a
 * `TabDefinition` object representing them. This object contains helpful,
 * pre-computed IDs for the tab and its associated panel that can be used to easily
 * associate the two with `aria-controls` and `aria-labelledby`.
 */

const useTabs = (
  tabLabels: string[],
  defaultSelected?: (typeof tabLabels)[number],
  opts: UseTabsOpts = {},
): UseTabsReturn => {
  const tabDefs = useMemo(() => tabLabels.map(tabDefFromLabel), [tabLabels]);

  const [selectedTab, setSelectedTab] = useState(
    (defaultSelected && tabDefs[tabLabels.indexOf(defaultSelected)]?.tabId) ?? tabDefs[0].tabId,
  );
  const idToTabDef = useMemo(
    () => Object.fromEntries(tabDefs.map((tab) => [tab.tabId, tab])),
    [tabDefs],
  );

  const labelToTabId = useMemo(
    () => Object.fromEntries(tabDefs.map((tab) => [tab.label, tab.tabId])),
    [tabDefs],
  );

  const parentoOnTabChange = opts.onTabChange;
  const onTabChange = useCallback(
    (tabId: string) => {
      setSelectedTab(tabId);
      parentoOnTabChange?.(idToTabDef[tabId].label);
    },
    [setSelectedTab, parentoOnTabChange, idToTabDef],
  );

  const selectTab = useCallback(
    (tabName: string) => {
      const tabDef = idToTabDef[labelToTabId[tabName]];
      if (!tabDef) {
        console.warn(`Tab ${tabName} not found`);
        return;
      }
      setSelectedTab(tabDef.tabId);
    },
    [idToTabDef, labelToTabId],
  );

  const tabContextValue: TabContextType = useMemo(
    () => ({ selectedTab, setSelectedTab, onTabChange, tabDefs }),
    [selectedTab, setSelectedTab, onTabChange, tabDefs],
  );

  return {
    selectedTab,
    labelToTabId,
    selectTab,
    context: tabContextValue,
  };
};

export default useTabs;
