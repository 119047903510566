import z from 'zod';
import { userSchema } from './user';
export const baseUserGroupSchema = z.object({
    id: z.string(),
    name: z.string(),
});
export const baseUserGroupByIdSchema = baseUserGroupSchema.extend({
    file: z.string().nullable(),
});
export const userGroupSchema = baseUserGroupSchema.extend({
    owner: z.lazy(() => userSchema).nullable(),
});
export const baseGroupDisplaySchema = baseUserGroupSchema.pick({
    id: true,
    name: true,
});
