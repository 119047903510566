import { ContractRateSet, ContractRateTypeOptions } from '@liftai/asset-management-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useContext } from 'react';

import { UserContext } from '~/auth/userContext';
import { formatCurrency } from '~/utils/format';

type ContractRateSetProps = {
  rateSet: ContractRateSet;
};

export const ContractRateSetDetail: React.FC<ContractRateSetProps> = ({ rateSet }) => {
  const { currencyCode } = useContext(UserContext);
  return (
    <Grid size={{ xs: 12 }}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', paddingBottom: 2 }}>
        Hourly Rates: {rateSet.name}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rate Type</TableCell>
              <TableCell>Worker Type</TableCell>
              <TableCell>Work Type</TableCell>
              <TableCell>Hourly Rate</TableCell>
              <TableCell>Covered by Contract</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rateSet.rates.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  {ContractRateTypeOptions[row.rateType] || row.rateType}
                </TableCell>
                <TableCell sx={{ textTransform: 'capitalize' }}>{row.workerType}</TableCell>
                <TableCell sx={{ textTransform: 'capitalize' }}>{row.workType}</TableCell>
                <TableCell>{formatCurrency({ value: row.hourlyRate, currencyCode })}</TableCell>

                <TableCell>{row.coveredByContract ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
