import useSWR from 'swr';

import { getApiClient } from '~/utils/api';

interface UseProvidersOptions {
  propertyId?: string;
}

export const useProviders = ({ propertyId }: UseProvidersOptions) => {
  const apiClient = getApiClient();

  const {
    data: providers,
    error,
    isLoading,
  } = useSWR(propertyId ? ['providers', propertyId] : null, async () => {
    const response = await apiClient.providers.filter({ propertyId });
    return response;
  });

  return {
    providers,
    error,
    loading: isLoading,
  };
};
